import { BrowserView, MobileView, MobileOnlyView } from 'react-device-detect';

export default function TwentyTwentyFourPDFPage(){
    return(
        <>
        <BrowserView>
        {/* <h1>Test</h1> */}
    <object data="./assets/pdf//2024-Healm Presentation-compressed.pdf" type="application/pdf" width="100%" height='1000'>
                <a href="./assets/pdf//2024-Healm Presentation-compressed.pdf">pdf</a>
            </object> 
        </BrowserView>

        <MobileOnlyView>
            <iframe
                style={{marginTop: '50px'}}
                title="."
                frameborder="0"
                scrolling="no"
                width="100%"
                height="600"
                src="https://drive.google.com/file/d/1Bz4_Ongapdti1aosJ8ktWef_mbrFb9iK/preview"
                >
                </iframe>
        </MobileOnlyView>
        </>

    )
}