import React from 'react';
import './DashboardComponent.css';
import chartIMG from './img/chart_and_analytics.png'
import gridBg from './img/grid_bg_full.png'
import spacer from './img/spacer-vert.png'
import mobileImg from './img/analytics_mobile.png'

const DashboardComponent = () => {
  return (
    <div className="dashboard-container">
      <img className="grid-bg-an desktop-only" src={gridBg} />
      <img 
        src={chartIMG}
        alt="Analytics Chart" 
        className="chart-image desktop-only"
      />
      <img 
        src={mobileImg}
        alt="Mobile Analytics" 
        className="mobile-image mobile-only"
      />

      <h1 className="title">Boost Organic Google Ranking</h1>
      <p className="description">
        Virtual tours are a powerful tool to boost your organic ranking on Google.
        Enhanced listings with virtual tours are favored by Google's search algorithms, giving
        your business better visibility.
      </p>

      <img style={{marginTop: '33px'}} src={spacer}/>
    </div>
  );
};

export default DashboardComponent;