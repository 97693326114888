import React, { useState } from 'react';
import './page.css';
import axios from 'axios';
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai';
import LoadingSpinner from '../../components/loading/spinner';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';

const customStyles = {
  overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: '1011111111110',

      backgroundColor: 'rgba(255, 255, 255, 0.2)'
    },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    backgroundColor: 'black',
    zIndex: '1011111111110',
    border: '2px solid green',
  //   padding: '5%',
    borderRadius: '15px'
  //   margin: '10%'
  },
};

const SignupSpecial = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    // Simple email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };


const handleSubmit = (e) => {
  e.preventDefault();
  setErrorMessage('');

  if (!email) {
    setErrorMessage('Please enter your email.');
    return;
  }
  if (!validateEmail(email)) {
    setErrorMessage('Please enter a valid email address.');
    return;
  }

  // Debugging output to check the values being sent
  console.log("Sending Email with:", {name, email, mobile});

  setLoadingSpinner(true);

  const templateParams = {
    to_name: name,
    to_email: email,  // Ensure this matches the variable name expected in your EmailJS template
    mobile: mobile
  };

    emailjs.send('service_qlxh36g', 'template_o2hmbvm', templateParams, '7YE24aFg3Ubd6hBoA')
      .then(response => {
        console.log('Email successfully sent!', response);
        setIsOpen(true);
      })
      .catch(err => {
        setErrorMessage('An error occurred while sending the email. Please try again later.');
        console.error('Email sending error:', err);
      })
      .finally(() => {
        setLoadingSpinner(false);
      });
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setErrorMessage('');

  //   // Validate email field
  //   if (!email) {
  //     setErrorMessage('Please enter your email.');
  //     return;
  //   }
  //   if (!validateEmail(email)) {
  //     setErrorMessage('Please enter a valid email address.');
  //     return;
  //   }

  //   setLoadingSpinner(true); // Move the loading spinner call here

  //   try {
  //     const response = await axios({
  //       method: 'post',
  //       url: `${process.env.REACT_APP_API_URL}signup/create-new`,
  //       data: {
  //         email: email,
  //         phone: mobile,
  //         name: name,
  //         password: 'your_password_here',
  //       },
  //       headers: { 'Content-Type': 'application/json' },
  //     });

  //     if (response.status === 200) {
  //       console.log('success!', response.data);
  //       setIsOpen(true);
  //     }
  //   } catch (err) {
  //     if (err.response) {
  //       if (err.response.status === 400) {
  //         setErrorMessage('Bad Request. Please check your input and try again.');
  //       } else if (err.response.status === 422) {
  //         setErrorMessage('Validation Error. Please provide all required fields.');
  //       } else {
  //         setErrorMessage('An error occurred. Please try again later.');
  //       }
  //     } else {
  //       setErrorMessage('An error occurred. Please try again later.');
  //     }
  //     console.log(err);
  //     console.log(err.response.data);
  //     console.log('status', err.response.status);
  //     console.log(err.response.headers);
  //     console.log('message', err.message);
  //   } finally {
  //     setLoadingSpinner(false);
  //   }
  // };

  const closeModal = () => {
    setIsOpen(false);
    navigate('/'); // Redirect to the homepage

  };

  return (
    <div className="signup-special">
      {loadingSpinner && <LoadingSpinner />}
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <AiOutlineClose
            style={{ cursor: 'pointer', fontSize: '30px', float: 'right' }}
            onClick={closeModal}
          />
          <div style={{ padding: '5%' }}>
            <h3 style={{ color: 'white' }}>
              Thank you, please check your email for further instructions.
            </h3>
            <br />
            If you don't receive an email within a few minutes, please check
            your Spam / Junk / Promotions folder.
          </div>
        </Modal>
      )}
      <div className="signup-special-content">
        <div className="logo" style={{ width: '250px' }}>
          <img src="./assets/healm-logo/White.png" alt="Healm Logo" />
        </div>
        <div className="signup-special-p">
          {/* Sign-up to our beta app to WIN $10 in Game Credits! */}
          Sign up to get early access to the Healm Mobile app, launching soon

        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="signup-special-input-group">
            <input
              type="text"
              id="name"
              placeholder=" "
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="name">Name</label>
          </div>
          <div className="signup-special-input-group">
            <input
              type="email"
              placeholder=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="name">Email</label>
          </div>
          <div className="signup-special-input-group">
            <input
              type="tel"
              placeholder=""
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
            <label htmlFor="name">Mobile</label>
          </div>
          <button type="submit">Sign up</button>
        </form>
      </div>
      <div className="signup-special-image">
        <h2>Hunt for the biggest prizes!</h2>
        <img src="/main-section-chest.png" alt="Treasure Chest" />
      </div>
    </div>
  );
};

export default SignupSpecial;