import React from 'react';
import './HowItWorksComponent.css';
import imgv2 from './img/how_it_works_v2.png'
import imgv4 from './img/how_it_works_v4.png'

import GetStartedButton from '../GetStartedButton/GetStartedButton';
import mainBg from './img/how_it_works_bg.png'
import imgv2_mobile from './img/how_it_works_mobile.png'
import mobileBlimp from './img/how_it_works_mobile_blimp.png'

const HowItWorksComponent = () => {
  return (
    <div className="how-it-works">
      <img className="how-bg" src={mainBg} alt="Background" />

      <h2>How It Works</h2>
      <div className="process-container">
        <div className="button-overlay">
          <GetStartedButton />
        </div>
        <img className="desktop-image" src={imgv4} alt="How it works process" />
        <img className="mobile-image" src={imgv2_mobile} alt="How it works process mobile" />
        <img className="mobile-blimp" src={mobileBlimp} alt="Mobile blimp" />
      </div>
    </div>
  );
};

export default HowItWorksComponent;