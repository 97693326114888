import './spinner.css'

export default function LoadingSpinner(){

    return(
        <div className='loading-bg-spinner'>
            <h1>Hello</h1>
            <h1>Hello</h1>
            <h1>Hello</h1>
            <h1>Hello</h1>
            <h1>Hello</h1>
            <h1>Hello</h1>
            <h1>Hello</h1>

            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>

        </div>
    )
}