import React from 'react';
import './GetStartedButton.css';
import buttonIcon from './get_started_icon.png'

const GetStartedButton = () => {
  const scrollToContactForm = () => {
    const contactForm = document.getElementById('contact');
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <button className="get-started-button" onClick={scrollToContactForm}>
      <img className="button-icon-1" src={buttonIcon} alt="Get Started Icon"/>
      Get Started
    </button>
  );
};

export default GetStartedButton;