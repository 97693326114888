export default function Navigation(){
    return (
        <header>
<div className="limit">
<a href="/" className="logo">
<img style={{width: '133px'}}src="/assets/061fedaf462a58f9fc00a140657b69b1c4d0d80c.png" alt="Blockverse"/>
</a>
<div className="navigation">
<div className="navigation__close"></div>
<div className="header__btn">
{/* <a href="/#subscribe-block" className="button stroked stroked_green">
Join Presale
</a> */}
<a href="/enter" className="button">
<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none" className="svg replaced-svg">
<path fillRule="evenodd" clipRule="evenodd" d="M10.9493 11.9224C11.6917 9.25308 13.8954 7.34192 16.5771 7.04164C19.9837 6.66025 23.091 8.93668 23.7752 12.3151C24.1228 14.0309 23.7022 16.0009 22.6936 17.3819L22.4365 17.734L23.1035 18.1704C23.4705 18.4104 24.1525 18.985 24.6192 19.4474C25.9338 20.7499 26.7573 22.1448 27.2829 23.9598C27.5293 24.8104 27.7356 26.4419 27.6527 26.884C27.6521 26.8874 27.6514 26.8908 27.6507 26.8941H25.8183C25.7714 26.7016 25.7401 26.4681 25.7205 26.188C25.5383 23.574 24.2063 21.2249 22.1032 19.8084C21.4525 19.3701 21.2028 19.1891 20.954 19.1903C20.7706 19.1912 20.5877 19.2911 20.2451 19.46C19.8594 19.6502 19.2079 19.8897 18.7975 19.9922C17.3355 20.3573 15.4665 20.1027 14.17 19.3618L13.7274 19.109L13.2894 19.3487C12.5239 19.7676 11.9882 20.171 11.3479 20.8107C9.90347 22.2535 9.13226 23.9393 8.92863 26.0987C8.89655 26.439 8.86387 26.6959 8.82001 26.8941H7.00508C6.99347 26.7266 7.00276 26.4766 7.0228 26.0559C7.16959 22.9803 8.88529 19.9415 11.4424 18.2278C11.8298 17.9681 12.1354 17.7165 12.1213 17.6686C12.1073 17.6206 12.0026 17.4573 11.8888 17.3056C10.8704 15.9487 10.4691 13.6491 10.9493 11.9224ZM21.7163 11.9744C21.1397 10.4893 19.8028 9.33418 18.2896 9.01355C17.5596 8.85883 17.0745 8.86103 16.3297 9.02236C14.7266 9.36949 13.4232 10.5423 12.8804 12.1258C12.5838 12.991 12.5965 14.2391 12.9109 15.1116C13.4356 16.5684 14.5678 17.6347 16.0595 18.0769C16.6548 18.2534 17.8825 18.2603 18.5196 18.0907C20.0947 17.6715 21.3101 16.4813 21.7922 14.8859C22.0425 14.0579 22.0077 12.7248 21.7163 11.9744Z" fill="white"></path>
</svg> Enter
</a>
</div>
</div>
</div>
</header>
    )
}