import { useState } from 'react';
// import axios from '../../api/axios';
import axios from 'axios';

import { Link } from "react-router-dom";
import Modal from 'react-modal';
// import {Navigate} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import SignUpForm from './registerForm';
import { AiOutlineClose } from 'react-icons/ai';
import LoadingSpinner from '../loading/spinner';
import { isMobile } from 'react-device-detect';
const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: '1011111111110',

        backgroundColor: 'rgba(255, 255, 255, 0.2)'
      },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      backgroundColor: 'black',
      zIndex: '1011111111110',
      border: '2px solid green',
    //   padding: '5%',
      borderRadius: '15px'
    //   margin: '10%'
    },
  };

  const MobileCustomStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: '1011111111110',

        backgroundColor: 'rgba(255, 255, 255, 0.2)'
      },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      backgroundColor: 'black',
      zIndex: '1011111111110',
      border: '2px solid green',
    //   padding: '5%',
      borderRadius: '15px',
    //   margin: '10%'
    width: '90%',
    height: 'auto'
    },
  };

export default function RegisterForm(){

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [passwordmatch, setPasswordmatch] = useState('');

    const [modalIsOpen, setIsOpen] = useState(false);

    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    let navigate = useNavigate();



    function closeModal() {
        navigate("/enter", { replace: true });

            // setIsOpen(false);
            // return <Navigate to='/login' />
        }
    
    const ThankYou = () =>{
        setIsOpen(true);
    }

    const spinnySpinner = () => {
        setLoadingSpinner(true);
    }
    const closeSpinner = () => {
        setLoadingSpinner(false);
    }

    const handleSubmit = async (e) => {
        // prevents the submit button from refreshing the page
        e.preventDefault();
        setErrorMessage('');

        console.log(username, email, phone, password);
        
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}signup/create-new`,
            data: {
                "email": email,
                "password": password,
                "phone": phone,
                "name": username
              },
            headers: { "Content-Type": "application/json" },
          })
            .then(response => {
                if (response.status === 200 )
                    console.log("success!", response.data);
                    setIsOpen(true);


            })
            .catch(err => {
                setErrorMessage(err.message);

                console.log(err);
                console.log(err.response.data.message);
                console.log(err.response.data);
                console.log('status', err.response.status);
                console.log(err.response.headers);
                console.log('message',err.message);


                // setErrorMessage(err.response.data.message);

            })
            ;
   
        // const data = response.data;
        // console.log(data);

    }

    return(
            <>
            { loadingSpinner ? <LoadingSpinner /> : null }

            <div className="enter-block">
                <div className="limit">
                    <div className="card-content">
                        <div className="reg-block">
                        <div className="logo"></div>
               
               {/* FORM HERE */}
                            <div class="form-title" style={{
                                fontWeight: '800',
                                fontSize: 'clamp(30px, 3.334vw, 40px)',
                                color: '#FFFFFF',
                                marginBottom: '35px',
                                textAlign: 'center'
                            }}>
                                Registration
                            </div>

                            {/* { loadingSpinner ? <LoadingSpinner /> : null } */}

                            <SignUpForm closeSpinner={closeSpinner} spinnySpinner={spinnySpinner} ThankYou={ThankYou}/>
                            
                            {/* <button onClick={spinnySpinner}>Loading Spinner</button> */}




              
{isMobile? (
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}

            style={MobileCustomStyles}
            // style={isMobile ? {MobileCustomStyles} : {customStyles}}
            contentLabel="Example Modal"
        >
            <AiOutlineClose style={{cursor: 'pointer', fontSize: '30px', float: 'right'}} onClick={closeModal} /> 
            
            <div style={{padding: '5%'}}>
                <h3 style={{color: 'white'}}>Thank you, please check your email for further instructions.</h3><br/>
                If you don't receive an email within a few minutes, please check your Spam / Junk / Promotions folder.
            </div>


        </Modal>

) : (
                    <Modal
                     isOpen={modalIsOpen}
                     onRequestClose={closeModal}
                    
                        style={customStyles}
                        // style={isMobile ? {MobileCustomStyles} : {customStyles}}
                        contentLabel="Example Modal"
                    >
                        <AiOutlineClose style={{cursor: 'pointer', fontSize: '30px', float: 'right'}} onClick={closeModal} /> 
                        
                        <div style={{padding: '5%'}}>
                            <h3 style={{color: 'white'}}>Thank you, please check your email for further instructions.</h3><br/>
                            If you don't receive an email within a few minutes, please check your Spam / Junk / Promotions folder.
                        </div>
                    
                    
                    </Modal>
                    )}

                    
                        </div>
                    </div>
                </div>
            </div>
            </>
        )

}