import React from 'react';
import './UpgradeSection.css';
import arrrowIcon from './img/icon_arrow.png'
import rocketIcon from './img/icon_rocket.png'
import iphones from './img/upgrade_iphones.png'
import gridbg from './img/upgrade_bg_grid.png'
import blurBg from './img/blur_1.png'
import ringBg from './img/ring_bg.png'

const UpgradeSection = () => {
  return (
    <section className="upgrade-section">
      <div className="upgrade-content">
        <img className="grid-bg" src={gridbg} alt="Background grid" />
        <img className="blur-bg" src={blurBg} alt="Blur background" /> 
        <img className="blur-bg-2" src={blurBg} alt="Blur background" /> 

        <div className="content-wrapper">
          <div className="upgrade-text">
            <div className="icon-text-wrapper">
              <img className="update-icon" src={rocketIcon} alt="Rocket icon"/>
              <div>
                <h2>Upgrade Your Google Business Listing</h2>
                <p>
                  Improve your online presence by upgrading your Google Business Listing
                  with immersive virtual tours. Stand out from the competition and provide
                  potential customers with an interactive experience.
                </p>
              </div>
            </div>
          </div>
          
          <div className="upgrade-text">
            <div className="icon-text-wrapper">
              <img className="update-icon" src={arrrowIcon} alt="Arrow icon"/>
              <div>
                <h2>Proven 20% Increase in Conversions</h2>
                <p>
                  Businesses that use virtual tours see a proven 20% increase in conversions.
                  Give your customers a compelling reason to choose your business
                  over the competition.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="upgrade-image">
        <img src={iphones} alt="Virtual tour on mobile" />
      </div>
    </section>
  );
};

export default UpgradeSection;