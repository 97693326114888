import React, { useState } from 'react';
import './TestimonialComponent.css';
import avatar1 from './img/avatar_1.png'
import avatar2 from './img/avatar_2.png'
import avatar3 from './img/avatar_3.png'
import backgroundImage from './img/quote.png';
import GetStartedButton from '../GetStartedButton/GetStartedButton';
import thisBg from './img/testimonial_bg.png'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import avatarCafe from './img/testimonial_cafe.png';
import avatarGallery from './img/testimonial_gallery.png';
import avatarSmile from './img/testimonial_smile.png'

const TestimonialComponent = () => {
  const testimonials = [
    {
      id: 1,
      name:  "Lisa",
      title: "Owner, Xplosion Bar & Bowl",
      content: "You have captured our business and brought it to life. We love it - Thank you so so much.",
      avatar: avatarCafe
    },
    {
      id: 3,
      name: "Fadi Yasmin",
      title: "Owner of FY Smile Double Bay",
      content: "The virtual tours have showcased our clinic's state-of-the-art facilities from home, enhancing our online presence and attracting new clients. We highly recommend Healm Virtual Tours for businesses looking to elevate their digital experience.",
      avatar: avatarSmile
    },
    {
      id: 2,
      name: "Alex Taylor",
      title: "Venue Manager, The Gallery, Adelaide Rooftop Bar & Function Venue",
      content: "The boost in our organic ranking and the extended time visitors spend on our site is remarkable. Highly recommend Healm’s services.",
      avatar: avatarGallery
    }

  ];

  const [currentIndex, setCurrentIndex] = useState(1);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const getCardIndex = (offset) => {
    const index = currentIndex + offset;
    if (index < 0) return testimonials.length - 1;
    if (index >= testimonials.length) return 0;
    return index;
  };

  return (
    <div className="testimonial-section-wrapper">
      <img className="this-bg" src={thisBg} alt="Testimonial background" />
      <div className="testimonial-section">
        <h2>Trusted and used Healm</h2>

        <div className="testimonial-container">
          <button className="nav-button prev" onClick={prevSlide}>
            <IoChevronBackOutline />
          </button>
          {[-1, 0, 1].map((offset) => {
            const cardIndex = getCardIndex(offset);
            const testimonial = testimonials[cardIndex];
            return (
              <div key={offset} className={`testimonial-card ${offset === 0 ? 'active' : 'side'}`}>
                {offset === 0 && <img src={backgroundImage} alt="Quote background" className="quote-background" />}
                <div className="testimonial-header">
                  <img src={testimonial.avatar} alt={testimonial.name} className="author-image" />
                  <div className="author-info">
                    <h4>{testimonial.name}</h4>
                    <p>{testimonial.title}</p>
                  </div>
                </div>
                <p className="testimonial-content">{testimonial.content}</p>
              </div>
            );
          })}
          <button className="nav-button next" onClick={nextSlide}>
            <IoChevronForwardOutline />
          </button>
        </div>
        <div className="get-started-button-container">
          <GetStartedButton/>
        </div>
      </div>
    </div>
  );
};

export default TestimonialComponent;