import React, { useRef } from 'react';
import Header from './components/header/header';
import './page.css';
import Hero from './components/hero/hero';
import UpgradeSection from './components/UpgradeSection/UpgradeSection';
import BenefitsSection from './components/BenefitsSection/BenefitsSection';
import DashboardComponent from './components/DashboardComponent/DashboardComponent';
import HowItWorksComponent from './components/HowItWorksComponent/HowItWorksComponent';
import TestimonialComponent from './components/TestimonialComponent/TestimonialComponent';
import HealmConsultationForm from './components/HealmConsultationForm/HealmConsultationForm';
import FooterVirtualTour from './components/footer/footer';
import GetStartedButton from './components/GetStartedButton/GetStartedButton';
import PartnersSection from '../HomePage/PartnersSection/PartnersSection';

const VirtualTourLandingPage = () => {
  const contactFormRef = useRef(null);

  const scrollToContactForm = () => {
    contactFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="landing-page">
      {/* <Header /> */}
      <div id="home">
        <Hero />
        {/* <GetStartedButton onClick={scrollToContactForm} /> */}
      </div>
      <div className="partners-wrapper" style={{
        width: '100%',
        overflow: 'hidden',
        position: 'relative'
      }}>
        <PartnersSection />
      </div>
      

      <div className="content-wrapper">

        <div id="services">
          <UpgradeSection />
          <BenefitsSection />
          <DashboardComponent />
          <HowItWorksComponent />
        </div>
        <div id="testimonials">
          <TestimonialComponent />
        </div>
        <div id="contact" ref={contactFormRef}>
          <HealmConsultationForm />
        </div>
        <FooterVirtualTour/>
      </div>
    </div>
  );
};

export default VirtualTourLandingPage;