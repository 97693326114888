import React, { useRef, useEffect, useState } from 'react';
import './PartnersSection.css'; // Make sure you have the corresponding CSS file

const Partner = ({ name, logoSrc, link }) => (
  <a href={link} className="partner">
    <img src={logoSrc} alt={name} />
  </a>
);

const PartnersSectionTour = () => {
  const originalPartners = [
    { name: 'Oculus', logoSrc: './partners/meta-new.png', link: 'https://www.meta.com/quest/' },
    // { name: 'Rebel', logoSrc: './partners/2.png', link: 'https://www.rebelsport.com.au/' },
    { name: 'Mattel', logoSrc: './partners/3.png', link: 'https://about.mattel.com/' },
    { name: 'Playstation', logoSrc: './partners/4.png', link: 'https://www.playstation.com' },
    { name: 'Samsung', logoSrc: './partners/5.png', link: 'www.samsung.com' },
    { name: 'menulog', logoSrc: './partners/6.png', link: 'https://www.menulog.com.au/' },
    { name: 'Kogan', logoSrc: './partners/kogan-logo.png', link: 'https://www.kogan.com/' },

    { name: 'Pokemon', logoSrc: './partners/8.png', link: 'https://www.pokemon.com/' },
    { name: 'goget', logoSrc: './partners/9.png', link: 'https://www.goget.com.au/' },
    { name: 'RayBan', logoSrc: './partners/10.png', link: 'https://www.ray-ban.com/' },
    { name: 'Google', logoSrc: './partners/google-partner.webp', link: 'https://www.google.com/' },

    // { name: 'BCF', logoSrc: './partners/11.png', link: 'https://www.bcf.com.au/' },
    { name: 'Microsoft', logoSrc: './partners/12.png', link: 'https://www.microsoft.com/' },
    { name: 'Hismile', logoSrc: './partners/13.png', link: 'https://hismileteeth.com/' },
    { name: 'Warner Bros', logoSrc: './partners/14.png', link: 'https://www.warnerbros.com/' },
    { name: 'Nintendo', logoSrc: './partners/15.png', link: 'https://www.nintendo.com/' },
    { name: 'Xbox', logoSrc: './partners/16.png', link: 'https://www.xbox.com/' },
    { name: 'Apple', logoSrc: './partners/17.png', link: 'https://www.apple.com/' },
    // { name: 'Supercheap Auto', logoSrc: './partners/18.png', link: 'https://www.supercheapauto.com.au/' },

    // Add other partners here
  ];
  const partners = [...originalPartners, ...originalPartners]; // Duplicate for continuous effect
  const scrollContainer = useRef(null);

  useEffect(() => {
    let isScrolling = true;
    const step = () => {
      if (!isScrolling || !scrollContainer.current) return;

      scrollContainer.current.scrollLeft += 1;
      if (scrollContainer.current.scrollLeft >= (scrollContainer.current.scrollWidth / 2)) {
        scrollContainer.current.scrollLeft = 0;
      }

      requestAnimationFrame(step);
    };

    requestAnimationFrame(step);

    return () => {
      isScrolling = false;
    };
  }, []);
  

  return (
    <div className="partners-section-tour">
      {/* <div className="partners-section-title">MEET OUR PARTNERS</div> */}
      <div className="partners-list" ref={scrollContainer}>
        {partners.map((partner, index) => (
          <Partner
            key={`${partner.name}-${index}`} // Unique key for each partner
            name={partner.name}
            logoSrc={partner.logoSrc}
            link={partner.link}
          />
        ))}
      </div>


    </div>
  );
};

export default PartnersSectionTour;
