import React from 'react';
import './BenefitsSection.css';
import iconChart from './img/icon_chart.png';
import iconPeople from './img/icon_people.png';
import ringBg from './img/ring_bg.png'

const BenefitsSection = () => {
  return (
    <div className="benefits-section-wrapper">
      <img className="ring-bg" src={ringBg} alt="Ring background" />
      <section className="benefits-section content-wrapper">
        <div className="benefit-item">
          <div className="benefit-icon">
            <img src={iconChart} alt="Chart Icon"/>
          </div>
          <h3>Multiply Click Rates on Google Business Listings</h3>
          <p>
            Increase site clicks through rate on your Google Business Listing.
            Virtual tours attract more attention and entice users to explore
            your business in detail.
          </p>
        </div>
        <div className="benefit-item">
          <div className="benefit-icon">
            <img src={iconPeople} alt="People Icon"/>
          </div>
          <h3>Keep Visitors Engaged 5-10 Times Longer</h3>
          <p>
            Virtual tours keep visitors on your site 5-10 times longer compared to static
            images or text. The interactive experience captivates potential customers,
            increasing the likelihood of conversions.
          </p>
        </div>
      </section>
    </div>
  );
};

export default BenefitsSection;