import React from 'react';
import { FaLinkedin, FaInstagram, FaEnvelope, FaFacebookF } from 'react-icons/fa';
import { FaXTwitter, FaLinkedinIn } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import './footer.css'; 
import footerBg from './img/footer_bg.png'

const FooterVirtualTour = () => {
  return (
    <div className="healm-ft-container">
      <img className="grid-bg-an" src={footerBg} alt="Footer background" />
      <div className="healm-ft-content">
        <div className="healm-ft-brand-section">
          <div style={{display: "flex"}}>
            <img src="./logo.png" alt="Healm Logo"/>
            <div style={{ marginLeft: '53px', paddingTop: '9px', borderLeft: '1px solid #505F9C'}}>
              <img style={{height: "33px", paddingLeft: '43px'}} src="./partners/google-partner-2.png" alt="Google Partner"/>
            </div>
          </div>
          <div className="healm-ft-brand-description">Looking to bring AR into your app experience? Whether you're an artist, major brand, or non-profit Niantic's in-house experts can help. We help innovative brands leverage realistic AR experiences to make deeper connections with their audiences, inspire change, and shape the next frontier in consumer technology.</div>
        </div>
        <div className="healm-ft-nav-links">
          <div className="healm-ft-nav-column">
          <ul>
              <li><Link to="/" className="footer-nav-link">PRIZES</Link></li>
              <li><Link to="/" className="footer-nav-link">EXCLUSIVE OFFERS</Link></li>
              <li><Link to="/" className="footer-nav-link">PORTALS</Link></li>
              <li><Link to="/" className="footer-nav-link">AR PRODUCT EXPERIENCES</Link></li>
            </ul>
          </div>
          <div className="healm-ft-nav-column">
            <ul>
              <li><Link to="/" className="footer-nav-link">AR PRODUCT EXPERIENCES</Link></li>
              <li><Link to="/" className="footer-nav-link">PORTALS</Link></li>
              <li><Link to="/" className="footer-nav-link">EXCLUSIVE OFFERS</Link></li>
              <li><Link to="/" className="footer-nav-link">PRIZES</Link></li>
            </ul>
          </div>
        </div>
        <div className="healm-ft-social-links">
            <a href="https://www.linkedin.com/company/healm-llc" target="_blank" rel="noopener noreferrer" className="healm-ft-social-icon">          
                <FaLinkedinIn />
            </a>
            <a href="https://www.linkedin.com/company/healm-llc" target="_blank" rel="noopener noreferrer" className="healm-ft-social-icon">          
                <FaInstagram />
            </a>
            <a href="https://twitter.com/healmxr" target="_blank" rel="noopener noreferrer" className="healm-ft-social-icon">          
                <FaXTwitter />
            </a>
            <a href="https://www.facebook.com/healmreality" target="_blank" rel="noopener noreferrer" className="healm-ft-social-icon">          
                <FaFacebookF />
            </a>
        </div>
      </div>
      <div className="healm-ft-bottom">
        <div className="healm-ft-policies">
          <Link to="/policy"><span>Cookie Policy</span></Link> | <Link to="/policy"><span>Privacy Policy</span></Link>
        </div>
        <div className="healm-ft-copyright">
          © Healm Ltd. 2023
        </div>
      </div>
    </div>
  );
};

export default FooterVirtualTour;