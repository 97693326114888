import Footer from "../../components/footer";
import Navigation from "../../components/navigation";
import RegisterForm from "../../components/user/register";
import { BrowserView, MobileView, MobileOnlyView } from "react-device-detect";
import NavigationFull from "../../components/navigationFull";

export default function RegisterPage(){
    return(
        <>
        <BrowserView>
            {/* <Navigation/> */}
            <NavigationFull/>

            <RegisterForm/>
            <Footer/>
        </BrowserView>

        <MobileOnlyView>
            <NavigationFull/>
            <RegisterForm/>
            <Footer/>
        </MobileOnlyView>

        </>
    )
}