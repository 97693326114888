import React, { useState } from 'react';
import './HealmConsultationForm.css';
import { BiSend } from "react-icons/bi";
import mainBg from './img/how_it_works_bg.png';
import emailjs from 'emailjs-com';

const HealmConsultationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    businessName: '',
    phoneNumber: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    const templateParams = {
      to_name: formData.name,
      to_email: formData.email,
      to_businessName: formData.businessName,
      to_phoneNumber: formData.phoneNumber,
      to_message: formData.message
    };

    emailjs.send('service_qlxh36g', 'template_9krkplm', templateParams, '7YE24aFg3Ubd6hBoA')
      .then((result) => {
        console.log('Email successfully sent!', result);
        setSubmitStatus('success');
        setFormData({
          name: '',
          email: '',
          businessName: '',
          phoneNumber: '',
          message: ''
        });
      })
      .catch((error) => {
        console.error('Email sending error:', error);
        setSubmitStatus('error');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="healm-consultation-container">
      <img className="form-bg" src={mainBg} alt="Background" />

      <h2 className="healm-consultation-title">Contact Healm</h2>
      <form onSubmit={handleSubmit} className="healm-consultation-form">
        <h3 className="healm-consultation-subtitle">Free consultation</h3>
        <div className="healm-form-row">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
            required
            className="healm-form-input"
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
            className="healm-form-input"
          />
        </div>
        <div className="healm-form-row">
          <input
            type="text"
            name="businessName"
            value={formData.businessName}
            onChange={handleChange}
            placeholder="Business Name"
            required
            className="healm-form-input"
          />
          <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Phone Number"
            required
            className="healm-form-input"
          />
        </div>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Message/Comments"
          required
          className="healm-form-textarea"
        ></textarea>

        <button type="submit" className="healm-submit-btn" aria-label="Send" disabled={isSubmitting}>
          <BiSend className="healm-submit-icon" />
        </button>

        {submitStatus === 'success' && <p className="submit-message success">Form submitted successfully!</p>}
        {submitStatus === 'error' && <p className="submit-message error">An error occurred. Please try again.</p>}
      </form>
    </div>
  );
};

export default HealmConsultationForm;