import React, { useState, useEffect, useRef } from 'react'
import { Phone } from 'react-telephone';
import './form-styles.css'
import { Link } from 'react-router-dom';
import { IoMdAlert } from 'react-icons/io';
import { GoStop } from 'react-icons/go';

import axios from 'axios';
import LoadingDots from '../loading/loadingDots';


const SignUpForm = (props) => {
  // we use the help of useRef to test if it's the first render
  const firstRender = useRef(true)

  // set a state variable which can be used to disable the save/submit button
  // we set it to true so that the form is disabled on first render
  const [disable, setDisabled] = useState(false)
  
  // we can also set error messages to display to the user
  const [nameError, setNameError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [verifypasswordError, setVerifyPasswordError] = useState(null)
  const [matchpasswordError, setMatchPasswordError] = useState(null)

  const [regError, setRegError] = useState(null)

  const [regLengthError, setRegLengthError] = useState(null)
  const [regDigitError, setRegDigitError] = useState(null)

  const [passregError, setPassRegError] = useState(null)


  const [errorMessage, setErrorMessage] = useState('');


  const [showError, setShowError] = useState(false)

  // set initial state value(s) for example:
  const [nameLabel, setNameLabel] = useState('')
  const [emailLabel, setEmailLabel] = useState('')
  const [phoneLabel, setPhoneLabel] = useState('')
  const [passwordLabel, setPasswordLabel] = useState('')
  const [verifypasswordLabel, setVerifyPasswordLabel] = useState('')

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  // for every change in our state this will be fired
  // we add validation here and disable the save button if required
  useEffect(() => {
  
    // we want to skip validation on first render
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    // here we can disable/enable the save button by wrapping the setState function
    // in a call to the validation function which returns true/false
    setDisabled(formValidation())
    
  }, [nameLabel, emailLabel, passwordLabel, verifypasswordLabel]) // any state variable(s) included in here will trigger the effect to run
  
  // here we run any validation, returning true/false
  const formValidation = () => {

    let newErrors = {}
    var error = false;


    // // Name Field
    // if (nameLabel === "") {
    //     setNameError("Required")
    //     error = true;
    // } 
    // else{
    //     setNameError(null)
    //     error = false;
    // }

    // Email Field

    if (emailLabel === "") {
      setEmailError("Required")
      error = true;
      // Sets validation invisible until text has been rendered
      setRegError(null);

    }  else {
        setEmailError(null)
        error = false;

        // Sets validation invisible until text has been rendered
        setRegError("Not a valid email")
        
        // This is for validation only after text has been entered.
        let str1 = "@"
        if (emailLabel.includes(str1) === false) {
            // setRegError("Valid Email?")
            error = true;
          }  else {
              setRegError(null)
              error = false;
          }
    }

    // Password Field
    if (passwordLabel === "") {
        setPasswordError("Required")
        error = true;

        // Errors
        setPassRegError(null);
        setRegLengthError(null);
        setRegDigitError(null);

    } 
    else{
        setPasswordError(null);
        // error = false;

        // Length
        if (passwordLabel.length >= 8) {
            setRegLengthError(null);
            // error = false;

          }  
          else {
            setRegLengthError("Must be at least 8 characters")
            error = true;

          }

        // One Digit
          if (!/\d/.test(passwordLabel)) {
            setRegDigitError("Must have at least 1 digit")
            error = true;

          }  
          else {
            setRegDigitError(null);
            // error = false;

          }

        // Another
        if (!/[A-Z]/.test(passwordLabel)) {
            setPassRegError("Must have 1 capital")
            error = true;

        }  
        else {
            setPassRegError(null);
            // error = false;

        }

        

    }

    // ReEnter Password Field

    if (verifypasswordLabel === passwordLabel) {
        setVerifyPasswordError(null)
        // error = false;
    } 
    else{
        setVerifyPasswordError("Not Matching");
        error = true;
    }

    if (error === true) {
        return true;
    }
  }

  const [isLoading, setIsLoading] = useState(false);

  
  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);


        // console.log(username, email, phone, password);
        console.log('test?');
        // props.spinnySpinner();

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}signup/create-new`,
            data: {
                "email": emailLabel,
                "password": passwordLabel,
                "phone": phoneLabel,
                "name": nameLabel
              },
            headers: { "Content-Type": "application/json" },
          }) 
        //   .then(function () {
        //     console.log('test?');
        //     setLoadingSpinner(true);
        //   })
            .then(response => {
                // console.log('stuck?');
                if (response.status === 200 )
                    console.log("success!", response.data);
                    // props.closeSpinner();
                    props.ThankYou();

            })
            .catch(err => {
                // console.log(err);
                setErrorMessage(err.response.data.detail);
                setIsLoading(false);
                // setShowError(err.response.data.detail);
                // console.log('1',err.response.data.message);
                // console.log('2',err.response.data);
                // console.log('3',err.response.data.detail);

                // console.log('status', err.response.status);
                // console.log(err.response.headers);
                // console.log('message',err.message);
            })
            ;  }

  return (
    <div className='reg-form'>
            

    <form onSubmit={ handleSave } >

    <div style={{marginBottom: '5%'}}>
        <div className="form-top-label">         

            <div className='form-top-label-text'>    
                <label style={{width: '100%'}}>Name</label>    
            </div> 

            <div className='form-top-label-notice'>            
                {/* { nameError && <p id="top-form-message"><IoMdAlert/>{nameError}</p> } */}
            </div>

        </div>
        <div style={{display: 'flex'}}>            
        </div>
        <input
            type      = "text"
            name      = "nameLabel"
            autoComplete="off"

            value     = { nameLabel }
            onChange  = { e => setNameLabel(e.target.value) }
        />
        {/* { nameError && <p id="form-message"><IoMdAlert/>{nameError}</p> } */}
    </div>



    <div style={{marginBottom: '5%'}}>
        <div className="form-top-label">         

            <div className='form-top-label-text'>    
                <label style={{width: '100%'}}>Email</label>    
            </div> 

            <div className='form-top-label-notice'>            
            { emailError && <p id="top-form-message"><IoMdAlert/>{emailError}</p> }
            </div>

        </div>

        <div style={{display: 'flex'}}>            
        </div>
        <input
            type      = "text"
            name      = "emailLabel"
            autoComplete="off"

            value     = { emailLabel }
            onChange  = { e => setEmailLabel(e.target.value) }
            required
        />

        {/* { emailError && <p id="form-message"><GoAlert/>{emailError}</p> } */}
        { regError && <p id="form-message-error"><GoStop/>{regError}</p> }
    </div>


    <div style={{marginBottom: '5%'}}>
        <div className="form-top-label">         

            <div className='form-top-label-text'>    
                <label style={{width: '100%'}}>Create Password</label>    
            </div> 

            <div className='form-top-label-notice'>            
                { passwordError && <div id="top-form-message"><IoMdAlert/>{passwordError}</div> }
            </div>
           
        </div>

        <input
            type      = "password"
            name      = "passwordLabel"
            value     = { passwordLabel }
            onChange  = { e => setPasswordLabel(e.target.value) }
            required
        />
      {/* { passwordError && <p id="form-message"><TbAlertTriangle/>{passwordError}</p> } */}
      {/* { passregError && <p>{passregError}</p> }
      { regLengthError && <p>{regLengthError}</p> }
      { regDigitError && <p>{regDigitError}</p> } */}

      <div style={{listStyle: 'none', marginTop: '10px', textAlign: 'left'}}>
          <li style={{textAlign: 'left'}}>{ passregError && <p id="form-message-error"><GoStop/>{passregError}</p> }</li>
          <li style={{textAlign: 'left'}}>{ regLengthError && <p id="form-message-error"><GoStop/>{regLengthError}</p> }</li>
          <li style={{textAlign: 'left'}}>{ regDigitError && <p id="form-message-error"><GoStop/>{regDigitError}</p> }</li>
      </div>

      </div>

      <div style={{marginBottom: '5%'}}>
        <div className="form-top-label">         

            <div className='form-top-label-text'>    
                <label style={{width: '100%'}}>Re-Enter Password</label>    
            </div> 

            <div className='form-top-label-notice'>            
                { passwordError && <div id="top-form-message"><IoMdAlert/>{passwordError}</div> }
            </div>
           
        </div>
            <input
                type      = "password"
                name      = "verifypasswordLabel"
                value     = { verifypasswordLabel }
                onChange  = { e => setVerifyPasswordLabel(e.target.value) }
                required
            />
            
            { verifypasswordError && <p id="form-message-error"><GoStop/>{verifypasswordError}</p> }

      {/* { verifypasswordError && <p id="form-error-msg">{verifypasswordError}</p> } */}
      </div>

      <div className='form-row'>
      <label>Phone</label>

        <Phone id="form-phone-component" defaultCountry="us">
            <Phone.Country id="phone-country-dropdown"/>
            <Phone.Number
                name      = "phoneLabel"
                value={phoneLabel}
                onChange={e => setPhoneLabel(e.target.value)}
                placeholder="Enter phone number" 
            />
        </Phone>
        </div>
        <br/>


        {errorMessage && (<span style={{color: 'red', textAlign: 'center'}}>{errorMessage}</span>)}

        {/* { matchpasswordError && <p>{matchpasswordError}</p> } */}
        {isLoading === false ?  <button id="somebtn" className="button" type="submit" disabled={disable}>Sign Up</button> : <button className="button" style={{background: 'gray'}}>Loading<LoadingDots/></button>}

      {/* <button id="somebtn" className="button" type="submit" disabled={disable} >Sign Up</button> */}
            <div class="links center">
                <Link to="/enter">I already have an account</Link>
            </div>
    </form>


    </div>

  )
  
}

export default SignUpForm