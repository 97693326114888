import { Link } from "react-router-dom";

import {isMobile} from 'react-device-detect';
import { MobileView, BrowserView } from "react-device-detect";

export default function Footer(){
    return(


            <div className="footer-new" style={{
                textAlign: "center",
                color: "var(--White, #FFF)",
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
                letterSpacing: "-0.16px",
                opacity: 0.5,
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                marginBottom: '1rem'
            }}>                
                <div className="copyright-new">Healm © 2021-2023</div>
            </div>

    )
}