import React, { useState, useCallback } from 'react';
import './header.css';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollToSection = useCallback((sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const start = window.pageYOffset;
      const target = section.getBoundingClientRect().top + start;
      const distance = target - start;
      const duration = 1000; // Adjust this value to control scroll speed (in milliseconds)
      let startTime = null;

      function animation(currentTime) {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = ease(timeElapsed, start, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(animation);
      }

      function ease(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
      }

      requestAnimationFrame(animation);
      setIsMenuOpen(false);
    }
  }, []);

  const SocialIcons = () => (
    <div className="social-icons">
      <div className="icon-wrapper">
        <a  href="https://www.facebook.com/healmreality" aria-label="Facebook"><FaFacebookF /></a>
      </div>
      <div className="icon-wrapper">
        <a href="https://www.linkedin.com/company/healm-llc" aria-label="Instagram"><FaInstagram /></a>
      </div>
      <div className="icon-wrapper">
        <a  href="https://twitter.com/healmxr" aria-label="Twitter"><FaTwitter /></a>
      </div>
      <div className="icon-wrapper">
        <a href="https://www.linkedin.com/company/healm-llc" aria-label="LinkedIn"><FaLinkedinIn /></a>
      </div>
    </div>
  );

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo">
          <img src='./logo.png' alt="HEALM Logo" />
        </div>
        <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
          <ul>
            <li><a href="#home" onClick={(e) => { e.preventDefault(); scrollToSection('home'); }}>HOME</a></li>
            <li><a href="#services" onClick={(e) => { e.preventDefault(); scrollToSection('services'); }}>SERVICES</a></li>
            <li><a href="#testimonials" onClick={(e) => { e.preventDefault(); scrollToSection('testimonials'); }}>TESTIMONIALS</a></li>
            <li><a href="#contact" onClick={(e) => { e.preventDefault(); scrollToSection('contact'); }}>CONTACT</a></li>
          </ul>
          <div className="mobile-social-icons">
            <SocialIcons />
          </div>
        </nav>
        <div className="desktop-social-icons">
          <SocialIcons />
        </div>
        <button className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? <IoMdClose /> : <GiHamburgerMenu />}
        </button>
      </div>
    </header>
  );
};

export default Header;