import React from 'react';
import './hero.css';
import heroImage from './healm_virtual_tour_header_bg.png';
import Header from '../header/header';
import { FaCamera } from 'react-icons/fa';
import { IoMdQrScanner } from "react-icons/io";
import GetStartedButton from '../GetStartedButton/GetStartedButton';

const Hero = () => {
  return (
    <div className="hero" style={{backgroundImage: `url(${heroImage})`}}>
      <div className="hero-overlay">
        <Header />
        <div className="hero-content">
          <h1 className="hero-title">
            Transform Your <span className="highlight">Google Business</span> Listing with <span className="highlight">Virtual Tours</span>
          </h1>
          {/* <button className="cta-button">
          <IoMdQrScanner /> Get Started
          </button> */}
          <GetStartedButton/>
          <p className="hero-subtitle">Get Started with a <span className="highlight">Free 30 Minute </span>Analysis Session</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;